import { useAuth0 } from '@auth0/auth0-react';

const Auth0LoginTrigger = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  if (!isAuthenticated) {
    loginWithRedirect({ appState: { targetUrl: `${window.location.pathname}${window.location.search}` } });
  }
  return null;
};

export default Auth0LoginTrigger;
