/* eslint-disable no-undef */
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return useCallback(() => isMounted.current, []);
};

const useAsyncState = (defaultValue) => {
  const isMounted = useIsMounted();
  const [value, setRawValue] = useState(defaultValue);
  const setValue = useCallback((newValue) => {
    if (isMounted()) {
      setRawValue(newValue);
    }
  }, []);
  return [value, setValue];
};

export function useAccessToken() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useAsyncState();
  useEffect(() => {
    async function getAccessToken() {
      const at = await getAccessTokenSilently();
      setAccessToken(at);
    }
    if (isAuthenticated) getAccessToken();
  });
  return accessToken;
}

/* export function useAccessToken() {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [accessToken, setAccessToken] = useAsyncState();
  useEffect(() => {
    async function getAccessToken() {
      const at = (await getIdTokenClaims()).__raw;
      setAccessToken(at);
    }
    if (isAuthenticated) getAccessToken();
  });
  return accessToken;
} */

export const AppAuth0Provider = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENTID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const callbackFunc = (appState) => {
    navigate(appState?.targetUrl || window.location.pathname);
  };

  function Wrapper({ children }) {
    const { isLoading, error } = useAuth0();
    if (isLoading) {
      return null;
    }
    if (error) {
      return (
        <div>
          An error occured:
          {error.message}
        </div>
      );
    }
    return <div>{children}</div>;
  }

  Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: window.location.origin /* redirectUri */,
        responseType: 'token id_token',
        scope: 'openid',
      }}
      onRedirectCallback={callbackFunc}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <Wrapper>{children}</Wrapper>
    </Auth0Provider>
  );
};

AppAuth0Provider.propTypes = {
  children: PropTypes.node.isRequired,
};
