import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10, schemeSet1, schemeSet2 } from 'd3-scale-chromatic';

export const range10 = scaleOrdinal(schemeCategory10).range();
export const range9 = scaleOrdinal(schemeSet1).range();
export const range8 = scaleOrdinal(schemeSet2).range();
export const signalColors = {
  r: '#E73F3F',
  o: '#FF8243',
  y: '#FFFB5A',
  g: '#44AF56',
};
